<template>
  <v-navigation-drawer
    right
    app
    id="eventDrawer"
    :width="'341'"
    clipped
    mobile-breakpoint="1400"
    class="pt-3"
    v-model="show_notifications"
    disable-resize-watcher
  >
    <div>
      <v-expansion-panels hover>
        <div id="background_filler" class="pt-2"></div>
        <div fixed id="search-field" class="px-2">
          <v-text-field
            label="Search for events/alerts"
            append-icon="search"
            v-model="search_str"
            class="pt-0 mt-0 assign_select assign_select_v font_400 event_filter"
            single-line
            hide-details
            dense
            height="32"
            full-width
            outlined
            rounded
          ></v-text-field>
        </div>
        <div fixed id="date_selector" class="px-2">
          <v-row>
            <v-col cols="9" class="py-0 pr-0">
              <v-text-field
                outlined
                dense
                readonly
                clearable
                v-model="display_date"
                label="Date"
                class="pt-0 mt-0 assign_select assign_select_v font_400 event_filter"
                single-line
                hide-details
                height="32"
                full-width
                rounded
              >
                <template v-slot:append
                  ><v-menu
                    nudge-top="9"
                    nudge-right="12"
                    :close-on-content-click="false"
                    open-on-click
                    left
                    offset-overflow
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        data-cy="btn_date_phenotype"
                        >mdi-calendar</v-icon
                      >
                    </template>

                    <v-card v-if="date">
                      <v-row>
                        <v-col>
                          <v-date-picker
                            v-model="date"
                            no-title
                            :max="todays_date"
                            event-color="#ff9900"
                          ></v-date-picker>
                        </v-col>
                      </v-row>
                    </v-card> </v-menu
                ></template>
              </v-text-field>
            </v-col>
            <v-col cols="3" class="py-0 pr-0 mt-1">
              <v-icon @click="change_date(false)" class="mr-1"
                >mdi-arrow-left</v-icon
              ><v-icon
                :disabled="date == todays_date"
                @click="change_date(true)"
                class="ml-2"
                >mdi-arrow-right</v-icon
              >
            </v-col>
          </v-row>
        </div>

        <div fixed id="type_camera_selector" class="px-2">
          <v-row>
            <v-col cols="6" class="py-0 pr-1"
              ><v-select
                class="pt-0 mt-0 assign_select assign_select_v font_400 event_filter"
                label="Type"
                :items="[
                  'All',
                  { text: 'General', value: 'GENERAL' },
                  { text: 'Calving', value: 'CALVING' },
                  { text: 'Predator', value: 'PREDATOR' },
                ]"
                v-model="type"
                hide-details
                dense
                height="32"
                full-width
                outlined
                rounded
              >
                <template v-slot:append>
                  <v-icon style="margin-top: -2px">mdi-dots-vertical</v-icon>
                </template>
              </v-select></v-col
            >
            <v-col cols="6" class="py-0 pl-1"
              ><v-select
                class="pt-0 mt-0 assign_select assign_select_v font_400 event_filter"
                label="Camera"
                :items="camera_names"
                v-model="camera"
                hide-details
                dense
                height="32"
                full-width
                outlined
                rounded
              >
                <template v-slot:append>
                  <v-icon style="margin-top: -2px">mdi-cctv</v-icon>
                </template>
              </v-select></v-col
            >
          </v-row>
        </div>
        <v-tabs
          class="tabs-field"
          v-model="tab"
          centered
          light
          icons-and-text
          fixed-tabs
          :show-arrows="false"
        >
          <v-tabs-slider
            :color="
              tab == 'ALERT'
                ? 'oc_red'
                : tab == 'WARNING'
                ? 'oc_orange'
                : 'oc_green'
            "
          ></v-tabs-slider>
          <!-- events tab  -->

          <!-- alerts tab  -->
          <v-tab href="#ALERT" class="oc_red--text pa-0">
            Alerts
            <v-badge
              offset-y="8"
              offset-x="-20"
              color="oc_red"
              :content="
                $store.getters.getEvents &&
                $store.getters.getEvents.alerts.length
                  ? $store.getters.getEvents.alerts.length
                  : '0'
              "
            >
              <v-icon color="oc_red">mdi-alert-outline</v-icon>
            </v-badge>
          </v-tab>
          <!-- notices tab  -->

          <v-tab href="#WARNING" id="warnings" class="oc_orange--text pa-0">
            Warnings
            <v-badge
              offset-y="8"
              offset-x="-20"
              color="oc_orange"
              :content="
                $store.getters.getEvents &&
                $store.getters.getEvents.warnings.length
                  ? $store.getters.getEvents.warnings.length
                  : '0'
              "
            >
              <v-icon color="oc_orange"
                >mdi-shield-alert-outline</v-icon
              ></v-badge
            >
          </v-tab>
          <v-tab href="#NOTICE" class="oc_green--text pa-0">
            Info
            <v-badge
              offset-y="8"
              offset-x="-20"
              color="oc_green"
              :content="
                $store.getters.getEvents &&
                $store.getters.getEvents.notices.length
                  ? $store.getters.getEvents.notices.length
                  : '0'
              "
            >
              <v-icon color="oc_green">mdi-information-outline</v-icon></v-badge
            >
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="items-field">
          <!-- alerts tab  -->

          <v-tab-item :key="1" value="ALERT" class="tab_item_notifications">
            <div class="eventbar">
              <EventCard kind="cameras" type="ALERT" class="tab" />
            </div>
          </v-tab-item>

          <!-- notices tab  -->

          <v-tab-item :key="2" value="WARNING" class="tab_item_notifications">
            <div class="eventbar">
              <EventCard kind="cameras" type="WARNING" />
            </div>
          </v-tab-item>
          <!-- events tab  -->

          <v-tab-item
            :key="3"
            value="NOTICE"
            light
            class="tab_item_notifications"
          >
            <div class="eventbar">
              <EventCard kind="cameras" type="NOTICE" />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-expansion-panels>
    </div>
    <v-dialog v-model="open_notification" max-width="900" :fullscreen="fullscreen_notification_img"
      ><v-card
        v-if="notifications && notifications[selected_notification_index]"
        ><v-card-title
          ><v-icon v-if="tab == 'ALERT'" class="mr-2" color="oc_red"
            >mdi-alert-outline</v-icon
          ><v-icon v-if="tab == 'WARNING'" class="mr-2" color="oc_orange"
            >mdi-shield-alert-outline</v-icon
          ><v-icon v-if="tab == 'INFO'" class="mr-2" color="oc_green"
            >mdi-information-outline</v-icon
          >{{ notifications[selected_notification_index].notification_title }} -
          <span style="color: grey" class="ml-2"
            >{{
              age(
                notifications[selected_notification_index].notification_datetime
              )
            }}
            old</span
          >
          <v-spacer></v-spacer
          ><span
            @click="
              nav_to_table(notifications[selected_notification_index].camera.id)
            "
            v-if="notifications[selected_notification_index].camera"
            class="mr-4"
            style="
              display: block;
              color: grey;
              font-size: 18px;
              margin-bottom: -2px;
              cursor: pointer;
            "
            ><v-icon>mdi-cctv</v-icon>
            {{ notifications[selected_notification_index].camera.camera_name }}
          </span>
          <span style="float: right">
            <v-icon
              :disabled="selected_notification_index == 0"
              @click="selected_notification_index -= 1"
              class="mr-1"
              >mdi-arrow-left</v-icon
            ><v-icon
              :disabled="
                selected_notification_index == notifications.length - 1
              "
              @click="selected_notification_index += 1"
              class="ml-1"
              >mdi-arrow-right</v-icon
            ></span
          > <v-icon class="ml-2 mt-1" large  v-if="fullscreen_notification_img" @click="fullscreen_notification_img = false">mdi-fullscreen-exit</v-icon></v-card-title
        ><v-card-text>

          <div style="position: relative">
            <!-- <inner-image-zoom
              :src="
                cookie_bucket +
                notifications[selected_notification_index]
                  .notification_media_url
              "
              :zoomSrc="
                cookie_bucket +
                notifications[selected_notification_index]
                  .notification_media_url
              "
            >
            </inner-image-zoom> -->
            <image-inf :img_src="cookie_bucket + notifications[selected_notification_index].notification_media_url" />
            <span
              id="fullscreen_notification_img"
              @click="fullscreen_notification_img = true"
              ><v-icon style="height: 100%; width: 100%" large
                >mdi-fullscreen</v-icon
              ></span
            >
            <span id="download_notification_img">
              <a
                :href="
                  cookie_bucket +
                  notifications[selected_notification_index]
                    .notification_media_url
                "
                style="height: 100%; width: 100%"
                download
              >
                <v-icon style="height: 100%; width: 100%">mdi-download</v-icon>
              </a></span
            >
          </div> </v-card-text
        ><v-card-subtitle>{{
          notifications[selected_notification_index].notification_description
        }}</v-card-subtitle>
      </v-card></v-dialog
    >
    <!-- <v-dialog
      max-width="1000"
      v-if="notifications && notifications[selected_notification_index]"
      v-model="fullscreen_notification_img"
      fullscreen
      ><v-card
        ><v-card-title
          ><v-spacer /><v-icon
            @click="fullscreen_notification_img = false"
            large
            >mdi-close</v-icon
          ></v-card-title
        ><v-img
          :src="
            cookie_bucket +
            notifications[selected_notification_index].notification_media_url
          "
        ></v-img></v-card
    ></v-dialog> -->
  </v-navigation-drawer>
</template>

<script>
import EventCard from "@/components/EventCard.vue";
//import AlertCard from "@/components/AlertCard.vue";
//import NoticeCard from "@/components/NoticeCard.vue";
//import InnerImageZoom from "vue-inner-image-zoom";
import { eventBus } from "../main.js";
import moment from "moment-timezone";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import ImageInf from './ImageInf.vue';


export default {
  components: { EventCard, ImageInf },
  data() {
    return {
      selected_date: false,
      ed_width: 341,
      event_items: [],
      todays_date: moment().format("YYYY-MM-DD"),
      two_weeks: moment().subtract(14, "days").format("YYYY-MM-DD"),
      date: null,
      search_str: "",
      selected_event_items: [],
      tab: null,
      show_notifications: false,
      type: "All",
      camera: "All",

      selected_notification_index: 0,
      open_notification: false,

      fullscreen_notification_img: false,
    };
  },
  methods: {
    change_date(next) {
      if (next) {
        this.date = moment(this.date, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        this.date = moment(this.date, "YYYY-MM-DD")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }
    },
    nav_to_table(device_id) {
      this.open_notification = false;
      this.$router.push({
        name: "videos",
        params: {
    
          cameraname: device_id,
     
        },
      });
    },
    age(date) {
      if (date) {
        if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "seconds"
            ) +
            " " +
            "second"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) < 60
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "seconds"
            ) +
            " " +
            "seconds"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "minutes"
            ) +
            " " +
            "minute"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) < 60
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "minutes"
            ) +
            " " +
            "minutes"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "hours"
            ) +
            " " +
            "hour"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) < 24
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "hours"
            ) +
            " " +
            "hours"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "days"
            ) +
            " " +
            "day"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) < 30
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "days"
            ) +
            " " +
            "days"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) == 1
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "months"
            ) +
            " " +
            "month"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) < 12
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "months"
            ) +
            " " +
            "months"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) <= 24
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "years"
            ) +
            " " +
            "year"
          );
        } else if (
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) > 24
        ) {
          return (
            moment().diff(
              moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
              "years"
            ) +
            " " +
            "years"
          );
        } else {
          return [false, false];
        }
      }
    },
    // onResize() {
    //   if (window.innerWidth < 600) {
    //     try {
    //       this.ed_width = window.innerWidth;
    //       document.getElementById("search-field").style.width =
    //         String(window.innerWidth - 16) + "px";
    //       document.getElementById("background_filler").style.width =
    //         String(window.innerWidth) + "px";
    //       document.getElementById("item_selector").style.width =
    //         String((window.innerWidth - 26) / 2) + "px";
    //       document.getElementById("eventDrawer").style.marginTop = "52px";
    //       document.getElementById("item_selector").style.marginLeft =
    //         "-" + String(window.innerWidth / 2) + "px";
    //       document
    //         .getElementById("event_date_btn")
    //         .getElementsByClassName("v-btn__content")[0].style.width =
    //         String((window.innerWidth - 26) / 2 - 32) + "px";
    //       document.getElementById("date_selector").style.marginRight =
    //         "-" + String(window.innerWidth / 2 - 4) + "px";
    //     } catch {
    //       return false;
    //     }
    //   } else {
    //     try {
    //       this.ed_width = 341;
    //       document.getElementById("search-field").style.width = "329px";
    //       document.getElementById("background_filler").style.width = "341px";
    //       document.getElementById("item_selector").style.width = "160px";
    //       document.getElementById("item_selector").style.marginLeft = "-170px";
    //       document.getElementById("eventDrawer").style.marginTop = "0px";
    //       document
    //         .getElementById("event_date_btn")
    //         .getElementsByClassName("v-btn__content")[0].style.width = "128px";
    //       //document.getElementById("date_selector").style.marginRight = "-168px";
    //     } catch {
    //       return false;
    //     }
    //   }
    // },
    clear_date() {
      this.date = null;
    },
    turnonoff() {
      if (this.show_notifications == false) {
        this.show_notifications = true;
        eventBus.$emit("indent_fb", true);
      } else {
        this.show_notifications = false;
        eventBus.$emit("indent_fb", false);
      }
    },
    populateCameras() {
      var all_cameras = this.$allCameras;

      for (const i in all_cameras) {
        this.event_items.push(all_cameras[i][0]);
      }
    },
  },
  created() {
    //this.$store.dispatch("DDB_GET_EVENTS", this.$globEventParams);

    eventBus.$on("turn_onoff", () => this.turnonoff());
    eventBus.$on("off", () => (this.show_notifications = false));
    eventBus.$on(
      "open_notification",
      (notification) => (
        (this.selected_notification_index = notification),
        (this.open_notification = true)
      )
    );
    // if (window.location.href.split("/")[3] != "cameras") {
    //   this.show_notifications = true;
    // } else {
    //   this.show_notifications = false;
    // }
    if (this.timezeone_offset) {
      // this.fetchVideos([], [], false, true, "DESC");
      this.date = moment().format("YYYY-MM-DD");
    }
  },
  mounted() {
    // this.onResize();
    // this.$nextTick(() => {
    //   window.addEventListener("resize", this.onResize);
    // });
  },
  computed: {
    display_date() {
      let week_days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      let week_day_index = moment(this.date, "YYYY-MM-DD").isoWeekday() - 1;
      if (this.date) {
        return this.date + ", " + week_days[week_day_index];
      } else {
        return "-";
      }
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
    notifications() {
      if (!this.$store.getters.getEvents) {
        return [];
      }
      if (this.tab == "ALERT") {
        return this.$store.getters.getEvents.alerts;
      } else if (this.tab == "WARNING") {
        return this.$store.getters.getEvents.warnings;
      } else {
        return this.$store.getters.getEvents.notices;
      }
    },
    timezeone_offset() {
      if (this.$store.getters.getSite) {
        return this.$store.getters.getSite[0];
      }
      return 0;
    },
    user() {
      return this.$store.getters.getUser;
    },
    camera_names() {
      var cams = ["All"];
      for (const i in this.$store.getters.getCameraObjects) {
        cams.push({
          text: this.$store.getters.getCameraObjects[i].camera_name,
          value: this.$store.getters.getCameraObjects[i].id,
        });
      }
      return cams;
    },
  },
  watch: {
    open_notification() {
      if (!this.open_notification) {
        this.selected_notification_index = false
      }
    },
    selected_notification_index() {
      eventBus.$emit("change_notification_index", this.selected_notification_index);
    },
    timezeone_offset(n, p) {
      if (!p) {
        this.date = moment().format("YYYY-MM-DD");
      }
    },
    user() {
      if (!this.user) {
        this.show_notifications = false;
      }
    },
    date() {
      var selected_date = moment
        .tz(this.date, "YYYY-MM-DDThh", "UTC")
        .tz(this.timezeone_offset ? this.timezeone_offset.site_timezone : "UTC")
        .format("YYYY-MM-DDThh");
      if (selected_date.slice(8, 10) != this.date.slice(8, 10)) {
        selected_date = moment(selected_date, "YYYY-MM-DDThh")
          .add(1, "days")
          .format("YYYY-MM-DDThh");
      }
      this.selected_date = selected_date;
    },

    search_str() {
      this.$globEventParams.search_str = this.search_str;
      eventBus.$emit("items_filter");
    },
    selected_date() {
      this.$globEventParams.date = this.selected_date;
      eventBus.$emit("items_filter");
    },

    camera() {
      this.$globEventParams.camera = this.camera;
      eventBus.$emit("items_filter");
    },
    type() {
      this.$globEventParams.type = this.type;
      eventBus.$emit("items_filter");
    },
  },
};
</script>

<style lang="css"  scoped>

.v-text-field__details {
  display: none;
}
#date_span_active {
  margin-right: 6px;
}
.eventbar {
  height: 100%;
}
@media only screen and (max-width: 900px) {
  #eventDrawer {
    z-index: 10;
    margin-top: 52px;
    position: absolute !important;
  }
}

#search-field {
  width: 329px;
  position: fixed !important;
  z-index: 3;
}

.tabs-field {
  position: fixed !important;
  margin-top: 120px;
  z-index: 4;
}
.items-field {
  margin-top: 200px;
}
#background_filler {
  position: fixed !important;
  height: 184px;
  width: 341px;
  top: -10px;
  z-index: 1;
}
#warnings {
  color: #f1c618;
}
#date_span {
  letter-spacing: 0 !important;
  margin-left: -4px;
}
#item_selector {
  width: 160px;
  border-radius: 0px;
  position: fixed !important;
  margin-left: -175px;
  margin-top: 54px;
  z-index: 3;
}
#event_date_btn > .v-btn__content {
  width: 129px;
}
#date_selector {
  width: 329px;
  border-radius: 0px;
  position: fixed !important;
  margin-top: 40px;
  z-index: 3;
}
#type_camera_selector {
  width: 329px;
  border-radius: 0px;
  position: fixed !important;
  margin-top: 80px;
  z-index: 3;
}
#event_date_btn {
  text-transform: lowercase;
  height: 50px;
  font-size: 16px;
}
</style>
